// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: calc(10px + 2vmin);
    min-height: 100vh;
  }

  .app-step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    p {
      margin: 8px;
    }

    .start-game-button {
      margin-top: 24px;
    }

    span {
      margin-bottom: 8px;
    }
  }

  .transfer-list-container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}
  `, "",{"version":3,"sources":["webpack://./src/avalon/components/homePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,6BAA6B;IAC7B,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;;IAEX;MACE,WAAW;IACb;;IAEA;MACE,gBAAgB;IAClB;;IAEA;MACE,kBAAkB;IACpB;EACF;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".pageContainer {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    font-size: calc(10px + 2vmin);\n    min-height: 100vh;\n  }\n\n  .app-step {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n\n    p {\n      margin: 8px;\n    }\n\n    .start-game-button {\n      margin-top: 24px;\n    }\n\n    span {\n      margin-bottom: 8px;\n    }\n  }\n\n  .transfer-list-container {\n    display: flex;\n    flex-direction: column;\n    margin-top: 16px;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
