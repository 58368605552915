import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    playerName: '',
    selectedSpecialCharacters: [
      "Assassin",
      "Merlin",
      ],
      assignedRoles: {},
};

const avalonSlice = createSlice({
    name: 'avalon',
    initialState,
    reducers: {
      updatePlayerName: (state, action) => {
        state.playerName = action.payload;
      },
      updateSpecialCharacters: (state, action) => {
        state.selectedSpecialCharacters = action.payload;
      },
      updateAssignedRoles: (state, action) => {
        state.assignedRoles = action.payload;
      }
    }
  });

export const {updatePlayerName, updateSpecialCharacters, updateAssignedRoles} = avalonSlice.actions;

export default avalonSlice.reducer;
