import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { updateSpecialCharacters } from '../../store/avalonSlice';
import { useDispatch } from 'react-redux';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList() {
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([
    "Percival", 
    "Morgana",
    "Mordred",
    "Oberon",
]);
  const [right, setRight] = React.useState([
    "Assassin",
    "Merlin"
]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    const rightValues = right.concat(leftChecked);
    setRight(rightValues);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));

    dispatch(updateSpecialCharacters(rightValues));
  };

  const handleCheckedLeft = () => {
    const assassinIndex = rightChecked.indexOf("Assassin");
    if (assassinIndex !== -1) {
        rightChecked.splice(assassinIndex, 1);
    }
    const merlinIndex = rightChecked.indexOf("Merlin");
    if (merlinIndex !== -1) {
        rightChecked.splice(merlinIndex, 1);
    }
    const rightValues = not(right, rightChecked);
    setLeft(left.concat(rightChecked));
    setRight(rightValues);
    setChecked(not(checked, rightChecked));
    dispatch(updateSpecialCharacters(rightValues));
  };

  const handleAllLeft = () => {
    const rightValues = [...right];
    const asassinIndex = rightValues.indexOf("Assassin");
    if (asassinIndex !== -1) {
      rightValues.splice(asassinIndex, 1);
    }
    const merlinIndex = rightValues.indexOf("Merlin");
    if (merlinIndex !== -1) {
      rightValues.splice(merlinIndex, 1);
    }
    setLeft(left.concat(rightValues));
    setRight(["Assassin", "Merlin"]);
  };

  const customList = (items) => (
    <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItemButton
              key={value}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid
        className="page-container"
        container spacing={2}
        justifyContent="center"
        alignItems="center">
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  );
}
