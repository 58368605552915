import React from 'react';
import '../App.css';

function LandingPageComponent() {
    return (
        <div className="App">
            <div className="App-header"> 
            <h1>Coming soon</h1>
            </div>
        </div>
    );
    
}

export default LandingPageComponent;
