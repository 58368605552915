import React, {useEffect, useState} from "react";
import './homePage.css';
import { io } from "socket.io-client";
import { Button, TextField } from "@mui/material";
import TransferList from './transferList';
import { useDispatch, useSelector } from 'react-redux';
import { updateAssignedRoles } from '../../store/avalonSlice';


const socket = io.connect("https://avalon-web-server-fe3d0343c46b.herokuapp.com/");
// const socket = io.connect("localhost:3001");

function HomePageComponent() {
    const [playerName, setStatePlayerName] = useState("");
    const [roomName, setStateRoomName] = useState("");
    const [step, setStateStep] = useState(0);
    const [playersJoined, setStatePlayersJoined] = useState([]);
    const [existingPlayers, setStateExistingPlayers] = useState([]);
    const [servantOfArthurCount, setStateServantOfArthurCount] = useState(1);
    const [minionOfModredCount, setStateMinionOfModredCount] = useState(1);
    const selectedSpecialCards = useSelector((state) => state.avalon.selectedSpecialCharacters);
    const assignedRoles = useSelector((state) => state.avalon.assignedRoles);
    const dispatch = useDispatch();

    useEffect(() => {
        socket.on("existing_players", (data) => {
            setStateExistingPlayers(data);
        });

        socket.on("players_in_game", (data) => {
            setStateExistingPlayers([]);
            setStatePlayersJoined(data.players);
        });

        socket.on("roles_assigned", (data) => {
            dispatch(updateAssignedRoles(data));
            handleNextButtonClick();
        })

        socket.on("player_disconnected", (data) => {
            setStatePlayersJoined(data);
        })
    });

    const handlePlayerNameChange = (event) => {
        setStatePlayerName(event.target.value);
    };

    const handleRoomNameChange = (event) => {
        setStateRoomName(event.target.value);
    };

    const handleServantOfArthurCountChange = (event) => {
        setStateServantOfArthurCount(event.target.value);
    }

    const handleMinionOfModredCountChange = (event) => {
        setStateMinionOfModredCount(event.target.value);
    }

    const handleNextButtonClick = () => {
        const newStep = step + 1;
        if (newStep === 2 && playerName === '') {
            return;
        }

        setStateStep(newStep);

        if (newStep === 1 && roomName !== '') {
            socket.emit("join_room", roomName);
        }


        if (newStep === 2) {
            socket.emit("add_player", 
            {
                userName: playerName,
                roomName: roomName,
            });
        }

    }

    const handleStartGameClick = () => {
        const playersInSession = new Set([
            playerName,
             ...playersJoined, 
             ...existingPlayers
            ]);
        const gameCharCount = servantOfArthurCount 
                            + minionOfModredCount 
                            + selectedSpecialCards.length;
        
        if (playersInSession.length < gameCharCount || gameCharCount < 6) {
            return;
        }
        socket.emit("start_game", {
            players: [...playersInSession],
            servantOfArthurCount: servantOfArthurCount,
            minionOfModredCount: minionOfModredCount,
            selectedSpecialCards: selectedSpecialCards,
            roomName: roomName,
        });
    }

    const getHtmlTemplate = () => {
        switch(step) {
            case 0:
                return  (
                <div className="app-step">
                        <h1>Avalon</h1>
                        <TextField 
                        className="entry-input"
                        label="Enter room name"
                        value={roomName}
                        onChange={handleRoomNameChange}
                        margin="normal"/>
                        <Button
                        className="next-button"
                        variant="contained"
                        onClick={handleNextButtonClick}>
                            Next
                        </Button>
                </div>
            );
            case 1:
                return (
                    <div className="app-step">
                        <h2>Enter your name</h2>
                        <TextField 
                        className="entry-input"
                        label="Enter your name"
                        value={playerName}
                        onChange={handlePlayerNameChange}
                        margin="normal"/>
                        <Button
                        className="next-button"
                        variant="contained"
                        onClick={handleNextButtonClick}>
                            Next
                        </Button>
                    </div>
                )
            case 2:
                return  (
                    <div className="app-step">
                        <h2>Welcome {playerName}</h2>
                        <h3>Players joined:</h3>
                            {[...new Set([playerName, ...existingPlayers, ...playersJoined])]
                                .map((player, key) => (
                                <div>
                                    <p key={key}>{player}</p>
                                </div>
                            ))}
            
                        <br/>
                        <TextField 
                            label="Number of Servant of Arthur"
                            type="number"
                            value={servantOfArthurCount}
                            onChange={handleServantOfArthurCountChange}
                            margin="normal"/>
                        <TextField 
                            label="Number of Minion of Modred"
                            type="number"
                            value={minionOfModredCount}
                            onChange={handleMinionOfModredCountChange}
                            margin="normal"/>
                        <TransferList className="transfer-list-container"/>
                        <Button
                            className="start-game-button" 
                            variant="contained"
                            onClick={handleStartGameClick}>
                                Start game
                        </Button>
                    </div>
                );
            case 3: 
                return (
                    <div className="app-step">
                        <p>{assignedRoles[playerName]}</p>
                    </div>
                );
            default:
        }
    };

    return(
        <div className="pageContainer">
            {getHtmlTemplate()}
        </div>
    );
}

export default HomePageComponent;